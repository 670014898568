import { Link } from 'react-router-dom';
import { useState } from 'react';
import SearchBar from './ReactSearchComponent';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Dropdown from 'react-bootstrap/Dropdown';
import { encodeId } from '../HelperMethods';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  // const [isGroupOpen, setIsGroupOpen] = useState(false);
  // const [isSoloOpen, setIsSoloOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const closeMenu = () => {
    setIsOpen(false);
  };

  // const toggleGroupMenu = () => {
  //   setIsGroupOpen(!isGroupOpen);
  // };

  const [showGroupDropdown, setShowGroupDropdown] = useState(false);
  const [showSoloDropdown, setShowSoloDropdown] = useState(false);

  const handleGroupMouseEnter = () => setShowGroupDropdown(true);
  const handleGroupMouseLeave = () => setShowGroupDropdown(false);

  const handleSoloMouseEnter = () => setShowSoloDropdown(true);
  const handleSoloMouseLeave = () => setShowSoloDropdown(false);

  // New function to close dropdowns only
  const closeDropdowns = () => {
    setShowGroupDropdown(false);
    setShowSoloDropdown(false);
  };

  const handleClick = async () => {
    try {
      const response = await fetch('/api/songs/random');
      const data = await response.json();
      const randomId = data[0].melon_song_id;

      if (randomId) {
        window.location.pathname = `/song/${encodeId(randomId)}`;
      }
    } catch (error) {
      console.error('Error fetching the random ID:', error);
    }
  };

  const tooltip = <Tooltip id="tooltip">surprise me!</Tooltip>;

  return (
    <header>
      <div className="navbar-container" id="Navbar">
        <div className="logo">
          <Link to="/" onClick={closeMenu} style={{ textDecoration: 'none' }}>
            <h2 style={{ margin: 0 }}>dubu</h2>
          </Link>
        </div>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <div className={isOpen ? 'open' : 'close'} id="myLinks">
          <div className="navbar-item">
            <Dropdown
              onMouseEnter={handleGroupMouseEnter}
              onMouseLeave={handleGroupMouseLeave}
              show={showGroupDropdown}>
              <Dropdown.Toggle variant="success" id="dropdown" className="dropdown-toggle">
                idol groups
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-menu">
                <Dropdown.Item
                  as={Link}
                  to="/artist-discovery/idol-groups"
                  state={{ is_idol: true, is_group: true }}
                  onClick={closeDropdowns}>
                  all idol groups
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to="/artist-discovery/idol-groups"
                  state={{ is_idol: true, is_group: true, gender: 'F' }}
                  onClick={closeDropdowns}>
                  girl groups
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to="/artist-discovery/idol-groups"
                  state={{ is_idol: true, is_group: true, gender: 'M' }}
                  onClick={closeDropdowns}>
                  boy groups
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to="/artist-discovery/idol-groups"
                  state={{ is_idol: true, is_group: true, gender: 'C' }}
                  onClick={closeDropdowns}>
                  co-ed groups
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="navbar-item">
            <Dropdown
              onMouseEnter={handleSoloMouseEnter}
              onMouseLeave={handleSoloMouseLeave}
              show={showSoloDropdown}>
              <Dropdown.Toggle variant="success" id="dropdown" className="dropdown-toggle">
                idol solos
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-menu">
                <Dropdown.Item
                  as={Link}
                  to="/artist-discovery/idol-solos"
                  state={{ is_idol: true, is_group: false }}
                  onClick={closeDropdowns}>
                  all idol solos
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to="/artist-discovery/idol-solos"
                  state={{ is_idol: true, is_group: false, gender: 'F' }}
                  onClick={closeDropdowns}>
                  female idol solos
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to="/artist-discovery/idol-solos"
                  state={{ is_idol: true, is_group: false, gender: 'M' }}
                  onClick={closeDropdowns}>
                  male idol solos
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="navbar-item">
            <Link to="/top-credits" style={{ textDecoration: 'none' }} onClick={closeDropdowns}>
              <p>credits</p>
            </Link>
          </div>

          <div className="navbar-item">
            <Link to="/about" style={{ textDecoration: 'none' }} onClick={closeDropdowns}>
              <p>about</p>
            </Link>
          </div>

          <div className="navbar-item">
            <Link to="/feedback" style={{ textDecoration: 'none' }} onClick={closeDropdowns}>
              <p>feedback</p>
            </Link>
          </div>
        </div>
        <div className="navbar-searchbar">
          <SearchBar />
        </div>
        <a href="javascript:void(0);" className="icon" onClick={toggleMenu}>
          <i className="fa fa-bars"></i>
        </a>
        <OverlayTrigger placement="bottom" overlay={tooltip}>
          <button href="javascript:void(0);" onClick={handleClick} type="random-song">
            {/* <h3 className="random-icon">⚅</h3> */}
            <img className="random-icon" src="/dubu_face_260.jpg" />
          </button>
        </OverlayTrigger>
      </div>
    </header>
  );
};

export default Navbar;
