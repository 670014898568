import Carousel from 'react-bootstrap/Carousel';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { encodeId } from './HelperMethods';
// import HomeCarouselCard from 'components/HomeCarouselCard';

const data = [
  {
    image: require('../images/20240901_le_sserafim_mobile.jpeg'),
    caption: 'le sserafim',
    description: 'act like an angel & dress like crazy',
    album: 11579884
  },
  {
    image: require('../images/20240901_jaehyun_mobile.jpg'),
    caption: 'jaehyun',
    description: 'J - debut album',
    album: 11564752
  },
  {
    image: require('../images/20240907_tzuyu_mobile.jpg'),
    caption: 'tzuyu',
    description: 'abouTZU - debut album',
    album: 11585369
  },
  {
    image: require('../images/20240907_meovv_mobile.jpeg'),
    caption: 'meovv',
    description: "we'll make 'em meow",
    album: 11586554
  }
];

function HomeCarouselMobile() {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} className="carousel">
      {data.map((slide, idx) => {
        return (
          <Carousel.Item key={idx}>
            <img
              className="carousel-home"
              loading="lazy"
              src={slide.image}
              alt="banner of the latest kpop releases"
            />
            <Link to={`/album/${encodeId(slide.album)}`} state={{ id: slide.album }}>
              <Carousel.Caption>
                <h3 style={{ 'font-size': '300%' }}>{slide.caption}</h3>
                <p>{slide.description}</p>
              </Carousel.Caption>
            </Link>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}
export default HomeCarouselMobile;
