import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-bottom">
        <h8>
          Email: <Link to="mailto:contact.dubu@gmail.com">contact.dubu@gmail.com</Link>
        </h8>
        <h8>
          <Link to="/privacy-policy">privacy policy</Link>
        </h8>
        <h8>
          {new Date().getFullYear()} dubu: database of your favorite KPOP idols, albums, songs, and
          credits.
        </h8>
        <h8>
          found incorrect data? report errors <Link to="/feedback">here</Link>.
        </h8>
      </div>
    </footer>
  );
}

export default Footer;
