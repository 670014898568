import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

// components
import ArtistList from '../components/ArtistList';
import PageNotFoundPage from './PageNotFoundPage';

const ArtistDiscoveryPage = () => {
  const [artists, setArtists] = useState(null);
  const [error, setError] = useState(null);
  let { state } = useLocation();

  let is_idol = state.is_idol;
  let is_group = state.is_group;
  let gender = state?.gender;

  useEffect(() => {
    const fetchArtists = async () => {
      const response = await fetch('/api/artists/');
      const json = await response.json();

      if (response.ok) {
        setArtists(json);
      } else {
        setError(response.status + ' - ' + json.error);
      }
    };
    fetchArtists();
  }, []);
  if (error) return <PageNotFoundPage msg={error} />;

  return (
    <div className="padding">
      <div className="artist-discovery">
        <Helmet>
          {artists && is_idol && is_group && <title>idol groups - dubu</title>}
          {artists && is_idol && !is_group && <title>idol solos - dubu</title>}
          {!artists && <title>loading artists - dubu</title>}
          <meta name="keywords" content="kpop, dubu, database, kpop credits" />
          <meta
            name="description"
            content="Artist Discovery: Find your favorite idol or learn about someone new!"
          />
        </Helmet>
        <div className="artists">
          <h4>
            discover {gender == 'F' && 'female '}
            {gender == 'M' && 'male '}
            {gender == 'C' && 'co-ed '}
            {!gender && 'all '}
            {is_idol && 'idol '}
            {is_group && 'groups'}
            {!is_group && 'solos'} (A-Z)
          </h4>
          <h6>
            <Link to="/artist-discovery-list">see list form</Link>
          </h6>
          <br></br>
          <div className="artist-discovery-details">
            {artists &&
              artists
                .filter((a) => a.is_idol == is_idol && a.is_group == is_group)
                .filter((a) => (gender ? a.gender == gender : a))
                .map((artist) => <ArtistList key={artist._id} artist={artist} credits={false} />)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtistDiscoveryPage;
