import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const AboutPage = () => {
  return (
    <div className="padding">
      <div className="about">
        <Helmet>
          <title>about us - dubu</title>
          <meta name="keywords" content="about, kpop, dubu, database, kpop credits" />
          <meta
            name="description"
            content="About page for dubu: a database of your favorite KPOP idols, albums, songs, and credits."
          />
        </Helmet>
        <div className="about-photo">
          <img
            className="about-photo-img"
            loading="lazy"
            alt="learn about dubu, website for kpop credits, artists, and song information"
            src="/iu-about.jpeg"
          />
        </div>
        <div className="text-section">
          <h3>👋 about us</h3>
          <p>
            Welcome to dubu, your go-to source for information about K-Pop songs, albums, and
            artists. Our mission is to make the world of K-Pop more accessible with a comprehensive
            English-language database, shining a spotlight on the talented artists, producers, and
            lyricists behind the music. Dive into your favorite artist&apos;s discography or
            discover someone new!
          </p>
        </div>
        <div className="text-section">
          <h3>⚙️ how it works</h3>
          <p>There are a few ways to explore artists, songs, and albums:</p>
          <p>
            <ul className="a-list">Check out latest releases on the home page.</ul>{' '}
            <ul className="a-list">
              Search for your favorite artists, albums, and songs via the search bar.
            </ul>{' '}
            <ul className="a-list">Browse categorized lists of idols.</ul>
            <ul className="a-list">Click on the icon on the top right for a surprise!</ul>
          </p>
          <br></br>
          <p>
            On each artist page, you can browse through insights for any associated groups, members,
            album/song releases, and music production credits. Any lyricist, composer, or arranger
            with credits for idol songs also has their own page, where you can find the songs
            they&apos;ve helped create.
          </p>
        </div>
        <div className="text-section">
          <h3>📝 notes</h3>
          <p>
            Since we are in beta, users should not expect complete artist information for non-idols
            or fully accurate Korean to English translations. For any mistranslations, please report
            them in the <Link to="/feedback">feedback form</Link>.
          </p>
          <p>Thank you for using dubu and we appreciate all feedback!</p>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
