import { Helmet } from 'react-helmet';

const PrivacyPolicyPage = () => {
  return (
    <div className="padding">
      <div className="about">
        <Helmet>
          <title>privacy policy - dubu</title>
          <meta name="keywords" content="privacy, kpop, dubu, database, kpop credits" />
          <meta name="description" content="dubu Privacy Policy" />
        </Helmet>
        <h3>🔒 privacy policy</h3>
        <br></br>
        <p>
          Information about your use of the site is shared with Google, who may combine it with
          other information you&apos;ve provided to them or they&apos;ve collected from your use of
          their services.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
