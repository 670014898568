import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import SongDisplayList from '../../components/ViewAll/SongDisplayList';
import ArtistHeaderDetails from '../../components/ViewAll/ArtistHeaderDetails';
import PageNotFoundPage from '../PageNotFoundPage';
import { Helmet } from 'react-helmet';
import { encodeId, decodeId } from '../../components/HelperMethods';

const ArtistCreditsIconPage = () => {
  let { state } = useLocation();
  const [artists, setArtist] = useState(null);
  const [songs, setSongs] = useState([]);
  const [error, setError] = useState(null);

  let artist_id;
  if (!state) {
    artist_id = decodeId(Number(window.location.href.split('/').slice(-2)[0]));
  } else {
    artist_id = state.id;
  }

  const type = window.location.href.split('credits-')[1];

  useEffect(() => {
    const fetchArtist = async () => {
      const response = await fetch('/api/artists/' + artist_id);
      const json = await response.json();

      if (response.ok) {
        setArtist(json);
      } else if (response.status == 404 && !error) {
        setError('Artist does not exist');
      } else {
        setError(response.status + ' - ' + json.error);
      }
    };

    const fetchSongs = async () => {
      var credit_letter = type.charAt(0).toUpperCase();
      if (credit_letter == 'W') {
        credit_letter = 'L';
      }

      const response = await fetch('/api/credits/artist/' + artist_id + '&' + credit_letter);
      var json = await response.json();

      if (response.ok) {
        json.sort((a, b) => new Date(b.release_date) - new Date(a.release_date));
        setSongs(json);
      } else {
        setError(response.status + ' - ' + json.error);
      }
    };

    fetchArtist();
    fetchSongs();
  }, []);
  if (error) return <PageNotFoundPage msg={error} />;
  return (
    <div className="padding">
      <div className="artist-album-details">
        <Helmet>
          {artists && <title>{artists[0].english_name} credits - dubu</title>}
          {!artists && <title>loading credits - dubu</title>}
          {artists && (
            <meta
              name="keywords"
              content={'kpop, dubu, database, kpop credits, ' + artists[0].english_name}
            />
          )}
          {!artists && <meta name="keywords" content={'kpop, dubu, database, kpop credits'} />}
          {artists && (
            <meta
              name="description"
              content={'Song production credits page for ' + artists[0].english_name}
            />
          )}
        </Helmet>
        {artists && <ArtistHeaderDetails artist={artists && artists[0]} type={type} />}

        <div className="view-header">
          <div>
            <Link to={`/artist/${encodeId(artist_id)}/credits`} state={{ id: artist_id }}>
              🗒 list view
            </Link>
          </div>
          <div className="icon-view-links2">
            🖼 icon views:{' '}
            <h7>
              <Link
                onClick={() => {
                  window.location.href = `/artist/${encodeId(artist_id)}/credits-featured`;
                }}
                state={{ id: artist_id }}>
                {' '}
                FEATURED
              </Link>{' '}
              <Link
                onClick={() => {
                  window.location.href = `/artist/${encodeId(artist_id)}/credits-written`;
                }}
                state={{ id: artist_id }}>
                {' '}
                WRITTEN
              </Link>{' '}
              <Link
                onClick={() => {
                  window.location.href = `/artist/${encodeId(artist_id)}/credits-composed`;
                }}
                state={{ id: artist_id }}>
                {' '}
                COMPOSED
              </Link>{' '}
              <Link
                onClick={() => {
                  window.location.href = `/artist/${encodeId(artist_id)}/credits-arranged`;
                }}
                state={{ id: artist_id }}>
                {' '}
                ARRANGED
              </Link>
            </h7>
          </div>
        </div>
        <div className="album-header">
          <h4 className="album-name">
            {' '}
            {songs && songs.length > 0 && type + ' (' + songs.length + ')'}
          </h4>
        </div>
        <div className="display-list">
          {songs &&
            songs.map((song) => (
              <SongDisplayList
                key={song.song_id}
                song={song}
                name={song.english_name}
                picture_url={song.picture_url}
                year={song.release_date.slice(0, 4)}
                artist={song.artist_english_names}
                artist_ids={song.artist_ids}
              />
            ))}
        </div>
        <div className="return">
          <Link to={`/artist/${encodeId(artist_id)}`} style={{ 'text-decoration': 'underline' }}>
            ⬅ artist page
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ArtistCreditsIconPage;
