import { Link } from 'react-router-dom';
import SongDisplayList from './ViewAll/SongDisplayList';

const CreditDetailsByType = ({ artist_id, credits, type }) => {
  return (
    <div className={'credited-' + type}>
      <h6>
        {/* <strong> */}
        {type.toUpperCase()} ({credits.length}):
        {/* </strong> */}
      </h6>
      <div className="display-list">
        {credits.slice(0, 5).map((credit) => (
          <SongDisplayList
            key={credit._id}
            song={credit}
            name={credit.english_name}
            picture_url={credit.picture_url}
            year={credit.release_date.slice(0, 4)}
            artist={credit.artist_english_names}
            artist_ids={credit.artist_ids}
          />
        ))}{' '}
      </div>
      <Link className="see-all" to={'./credits-' + type} state={{ id: artist_id }}>
        <u>see all {type}</u>
      </Link>
    </div>
  );
};

export default CreditDetailsByType;
