import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import AllSongDetails from '../../components/ViewAll/AllSongsListDetails';
import ArtistHeaderDetails from '../../components/ViewAll/ArtistHeaderDetails';
import PageNotFoundPage from '../PageNotFoundPage';
import { Helmet } from 'react-helmet';
import { encodeId } from '../../components/HelperMethods';

const comparisons = {
  oldest: (a, b) => new Date(a.release_date) - new Date(b.release_date),
  newest: (a, b) => new Date(b.release_date) - new Date(a.release_date),
  alphabetical: (a, b) => a.english_name.localeCompare(b.english_name)
};

function overlap(arr1, arr2) {
  return arr1.some((item) => arr2.includes(item));
}

function ArtistCreditsPage() {
  let { state } = useLocation();
  const [artists, setArtist] = useState(null);
  const [songs, setSongs] = useState([]);
  const [allSongs, setAllSongs] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState('newest');
  const [selectedFilters, setSelectedFilters] = useState(['L', 'C', 'A']);
  const [error, setError] = useState(null);

  let artist_id;
  if (!state) {
    artist_id = Number(window.location.href.split('/').slice(-2)[0]);
  } else {
    artist_id = state.id;
  }

  useEffect(() => {
    const fetchArtist = async () => {
      const response = await fetch('/api/artists/' + artist_id);
      const json = await response.json();

      if (response.ok) {
        setArtist(json);
      } else if (response.status == 404 && !error) {
        setError('Artist does not exist');
      } else {
        setError(response.status + ' - ' + json.error);
      }
    };

    const fetchSongs = async () => {
      const response = await fetch('/api/credits/artist/' + artist_id);
      var json = await response.json();

      if (response.ok) {
        json.sort((a, b) => new Date(b.release_date) - new Date(a.release_date));
        setSongs(json);
        setAllSongs(json);
      } else {
        setError(response.status + ' - ' + json.error);
      }
    };

    fetchArtist();
    fetchSongs();
  }, []);
  if (error) return <PageNotFoundPage msg={error} />;

  return (
    <div className="padding">
      <div className="artistCredits">
        <Helmet>
          {artists && <title>{artists[0].english_name} credits - dubu</title>}
          {!artists && <title>loading credits - dubu</title>}
          {artists && (
            <meta
              name="keywords"
              content={'kpop, dubu, database, kpop credits, credits, ' + artists[0].english_name}
            />
          )}
          {!artists && (
            <meta name="keywords" content={'kpop, dubu, database, kpop credits, credits'} />
          )}
          {artists && (
            <meta
              name="description"
              content={'Song production credits list for ' + artists[0].english_name}
            />
          )}
        </Helmet>
        {artists && <ArtistHeaderDetails artist={artists && artists[0]} type="credits" />}
        <div className="credits-header">
          <div>
            <select
              multiple={true}
              value={selectedFilters}
              onChange={(e) => {
                const options = [...e.target.selectedOptions];
                const values = options.map((option) => option.value);
                setSelectedFilters(values);
                setSongs(
                  allSongs.filter((x) => {
                    return overlap(values, x.credit_type);
                  })
                );
              }}>
              <option value="L">lyrics</option>
              <option value="C">composing</option>
              <option value="A">arranging</option>
            </select>
            <select
              value={selectedOrder}
              onChange={(e) => {
                setSelectedOrder(e.target.value);
                setSongs(songs.sort(comparisons[e.target.value]));
              }}>
              <option value="newest">newest to oldest</option>
              <option value="oldest">oldest to newest</option>
              <option value="alphabetical">alphabetical</option>
            </select>
          </div>
          <div className="icon-view-links">
            🖼 icon views:{' '}
            <h7>
              <Link
                to={`/artist/${encodeId(artist_id)}/credits-featured`}
                state={{ id: artist_id }}>
                {' '}
                FEATURED
              </Link>{' '}
              <Link to={`/artist/${encodeId(artist_id)}/credits-written`} state={{ id: artist_id }}>
                {' '}
                WRITTEN
              </Link>{' '}
              <Link
                to={`/artist/${encodeId(artist_id)}/credits-composed`}
                state={{ id: artist_id }}>
                {' '}
                COMPOSED
              </Link>{' '}
              <Link
                to={`/artist/${encodeId(artist_id)}/credits-arranged`}
                state={{ id: artist_id }}>
                {' '}
                ARRANGED
              </Link>
            </h7>
          </div>
        </div>
        <AllSongDetails songs={songs} artist={artists && artists[0]} credits={true} />
      </div>
    </div>
  );
}

export default ArtistCreditsPage;
