import { Link } from 'react-router-dom';
import { TruncateName } from '../../components/HelperMethods';
import { encodeId } from '../../components/HelperMethods';

const SongDisplayList = ({ song, name, picture_url, year, artist, artist_ids }) => {
  const song_id = song.song_id;

  var release = '';
  if (year) {
    release = year.toString().slice(0, 10).replaceAll('-', '.');
  }

  // if (song && song?.artist_ids?.length > 0) {
  // }

  return (
    <div className="album-list">
      <div className="song-metadata">
        {release && artist && artist_ids && artist_ids.length == 1 && (
          <h8>
            {release} ·{' '}
            <Link
              key={artist_ids}
              state={{ id: artist_ids }}
              onClick={() => {
                window.location.href = `/artist/${encodeId(artist_ids)}`;
              }}>
              {TruncateName(artist, 30)}
            </Link>
          </h8>
        )}
        {release && artist && artist_ids && artist_ids.length > 1 && (
          <h8>
            {release} · {TruncateName(artist.join(', '), 30)}
          </h8>
        )}
        {release && !artist && <h8>{release}</h8>}
      </div>
      <Link
        key={song_id}
        to={`/song/${encodeId(song_id)}`}
        state={{ song_id: song_id, album_id: song.melon_album_id }}>
        {song.is_title && (
          <p>
            ✰ <strong>{TruncateName(name)}</strong>
          </p>
        )}
        {!song.is_title && (
          <p>
            <strong>{TruncateName(name)}</strong>
          </p>
        )}
        <img
          className="album-display-photo"
          src={picture_url}
          alt={'album photo of song ' + song.english_name}
          onError={(event) => {
            event.target.src = '/dubu_face_260.jpg';
            event.onerror = null;
          }}
        />
      </Link>
    </div>
  );
};

export default SongDisplayList;
