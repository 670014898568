export default function PhotoMethods(url) {
  if (!url) {
    return '';
  } else {
    return url.replace(/\/resize\/.*/, '/resize/461/quality/80/optimize');
  }
}

export function TruncateName(name, max_char = 17) {
  const ellipses = '...';
  if (!name) {
    return '';
  } else if (name.length < max_char + ellipses.length) {
    return name;
  }

  var trunc_name = '';
  const phrases = name.split(',');
  for (let j = 0; j < phrases.length; j++) {
    let words = phrases[j].split(' ');
    for (let i = 0; i < words.length; i++) {
      if (words[i].length + trunc_name.length >= max_char) {
        return trunc_name.trim() + ellipses;
      } else {
        trunc_name = trunc_name + ' ' + words[i];
      }
    }
    if (phrases.length > 1 && j < phrases.length - 1) {
      trunc_name += ',';
    }
  }
  return '';
}

export function encodeId(id, new_base = 9) {
  return (Number(id) + 1).toString(new_base);
}

export function decodeId(id, old_base = 9) {
  return parseInt(Number(id), old_base) - 1;
}
// export default { PhotoMethods, TruncateName };
