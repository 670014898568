import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import AllSongDetails from '../../components/ViewAll/AllSongsListDetails';
import ArtistHeaderDetails from '../../components/ViewAll/ArtistHeaderDetails';
import PageNotFoundPage from '../PageNotFoundPage';
import { Helmet } from 'react-helmet';

const comparisons = {
  oldest: (a, b) => new Date(a.release_date) - new Date(b.release_date),
  newest: (a, b) => new Date(b.release_date) - new Date(a.release_date),
  alphabetical: (a, b) => a.english_name.localeCompare(b.english_name)
};

function ArtistSongsPage() {
  let { state } = useLocation();
  const [artists, setArtist] = useState(null);
  const [songs, setSongs] = useState(null);
  const [allSongs, setAllSongs] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState('newest');
  const [error, setError] = useState(null);

  let artist_id;
  if (!state) {
    artist_id = Number(window.location.href.split('/').slice(-2)[0]);
  } else {
    artist_id = state.id;
  }

  useEffect(() => {
    const fetchArtist = async () => {
      const response = await fetch('/api/artists/' + artist_id);
      const json = await response.json();

      if (response.ok) {
        setArtist(json);
      } else if (response.status == 404 && !error) {
        setError('Artist does not exist');
      } else if (response.status == 400 && !error) {
        setError('Invalid artist');
      } else {
        setError(response.status + ' - ' + json.error);
      }
    };

    const fetchSongs = async () => {
      const response = await fetch('/api/songs/artist/' + artist_id);
      const json = await response.json();

      if (response.ok) {
        if (selectedOrder === 'oldest') {
          json.sort((a, b) => new Date(a.release_date) - new Date(b.release_date));
        } else if (selectedOrder === 'alphabetical') {
          json.sort((a, b) => a.english_name.localeCompare(b.english_name));
        } else {
          json.sort((a, b) => new Date(b.release_date) - new Date(a.release_date));
        }

        setSongs(json);
        setAllSongs(json);
      } else if (response.status == 400 && !error) {
        setError('Invalid artist');
      } else {
        setError(response.status + ' - ' + json.error);
      }
    };

    fetchArtist();
    fetchSongs();
  }, []);

  if (error) return <PageNotFoundPage msg={error} />;

  return (
    <div className="padding">
      <div className="artist-songs-page">
        <Helmet>
          {artists && <title>{artists[0].english_name} songs - dubu</title>}
          {!artists && <title>loading songs - dubu</title>}
          {artists && (
            <meta
              name="keywords"
              content={'kpop, dubu, database, kpop credits, ' + artists[0].english_name}
            />
          )}
          {!artists && <meta name="keywords" content={'kpop, dubu, database, kpop credits'} />}
          {artists && (
            <meta name="description" content={'Artist songs page for ' + artists[0].english_name} />
          )}
        </Helmet>
        {artists && <ArtistHeaderDetails artist={artists && artists[0]} type="songs" />}
        <div className="credits-header">
          <FormControlLabel
            control={
              <Switch
                color="default"
                onChange={(e) => {
                  if (e.target.checked) {
                    setSongs(
                      allSongs.filter((x) => {
                        return x.is_title;
                      })
                    );
                  } else {
                    setSongs(allSongs);
                  }
                }}
              />
            }
            label="title"
          />
          <select
            value={selectedOrder}
            onChange={(e) => {
              setSelectedOrder(e.target.value);
              setSongs(songs.sort(comparisons[e.target.value]));
            }}>
            <option value="newest">newest to oldest</option>
            <option value="oldest">oldest to newest</option>
            <option value="alphabetical">alphabetical</option>
          </select>
        </div>
        <AllSongDetails songs={songs} credits={false} />
      </div>
    </div>
  );
}

export default ArtistSongsPage;
