import { Link } from 'react-router-dom';
import { TruncateName, encodeId } from './HelperMethods';
// import Tooltip from 'react-bootstrap/Tooltip';
// import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';

const AlbumList = ({ album }) => {
  const id = album.melon_album_id;
  let release_date = album.release_date.slice(0, 4);
  // const tooltip = <Tooltip id="tooltip">{album.english_name}</Tooltip>;
  // var name = album.english_name
  //   .toLowerCase()
  //   .replaceAll(' ', '-')
  //   .replaceAll(/"([^"]+(?="))"/g, '')
  //   .replaceAll("'", '') // TODO simplify this
  //   .replaceAll('/', '-');

  return (
    <div className="album-list">
      {/* <OverlayTrigger placement="bottom" overlay={tooltip} trigger="hover"> */}
      <Link to={`/album/${encodeId(id)}`} state={{ id: id }}>
        <h8>{release_date}</h8>
        <p>
          <strong>{TruncateName(album.english_name)}</strong>
        </p>
        <img
          className="album-display-photo"
          src={album.picture_url}
          alt={'album photo of ' + album.english_name}
          onError={(event) => {
            event.target.src = '/dubu_face_260.jpg';
            event.onerror = null;
          }}
        />
      </Link>
      {/* </OverlayTrigger> */}
    </div>
  );
};

export default AlbumList;
