import { Helmet } from 'react-helmet';
import { useLocation, Link } from 'react-router-dom';

const SearchResultsPage = () => {
  let { state } = useLocation();
  const results = state.results;
  var artist_results = results.filter((r) => r.name.includes('[idol]'));
  artist_results = [...artist_results, ...results.filter((r) => r.name.includes('[artist]'))];
  const album_results = results.filter((r) => r.name.includes('[album]'));
  const song_results = results.filter((r) => r.name.includes('[song]'));
  console.log('page', results);

  return (
    <div className="padding">
      <div className="not-found">
        <Helmet>
          <title>search results</title>
        </Helmet>
        <h3 className="main-text">Search Results</h3>
        <br></br>
        <h5>artists</h5>
        {artist_results &&
          artist_results.map((result) => (
            <Link key={result.id} to={`/artist/${result.id}`}>
              <p>{result.name}</p>
            </Link>
          ))}
        <br></br>
        <h5>albums</h5>
        {album_results &&
          album_results.map((result) => (
            <Link key={result.id} to={`/album/${result.id}`}>
              <p>{result.name}</p>
            </Link>
          ))}

        <br></br>
        <h5>songs</h5>
        {song_results &&
          song_results.map((result) => (
            <Link key={result.id} to={`/song/${result.id}`}>
              <p>{result.name}</p>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default SearchResultsPage;
