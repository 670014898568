import { useEffect, useState } from 'react';

// components
import ArtistList from '../components/ArtistList';
import PageNotFoundPage from './PageNotFoundPage';
import { Helmet } from 'react-helmet';

const TopCreditsPage = () => {
  const [credits, setCredits] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTopCredits = async () => {
      const response = await fetch('/api/artists/top-credits/');
      const json = await response.json();

      if (response.ok) {
        setCredits(json);
      } else {
        setError(response.status + ' - ' + json.error);
      }
    };

    fetchTopCredits();
  }, []);
  if (error) return <PageNotFoundPage msg={error} />;

  return (
    <div className="padding">
      <div className="top-credits-page">
        <Helmet>
          <title>popular credits - dubu</title>
          <meta name="keywords" content="kpop, dubu, database, kpop credits, credits" />
          <meta
            name="description"
            content="Popular Credits Page: Discover the producers and songwriters of your favorite K-POP songs!"
          />
        </Helmet>
        <div className="artists">
          <h4>popular kpop credits</h4>
          <br></br>
          <div className="artist-discovery-details">
            {credits &&
              credits.map((artist) => (
                <ArtistList key={artist._id} artist={artist} credits={true} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopCreditsPage;
