import { Helmet } from 'react-helmet';

const FeedbackPage = () => {
  return (
    <div className="padding">
      <div className="feedback">
        <Helmet>
          <title>feedback - dubu</title>
          <meta name="keywords" content="kpop, dubu, database, kpop credits" />
          <meta
            name="description"
            content="dubu Feedback Page: Suggest new features and report issues"
          />
        </Helmet>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSehQ5QFnvk6IlrFxQCe7td5C_a7qL9lJhoKVG9wc_gIq18X3w/viewform?embedded=true"
          width="640"
          height="685">
          Loading…
        </iframe>
      </div>
    </div>
  );
};

export default FeedbackPage;
