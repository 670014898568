import { Link } from 'react-router-dom';
import { TruncateName } from '../HelperMethods';
import { encodeId } from '../HelperMethods';

const AllAlbumsDetails = ({ album }) => {
  const id = album.melon_album_id;
  const album_year = String(album.release_date).substring(0, 4);

  return (
    <div className="album-list">
      <h8>{album_year}</h8>
      <Link to={`/album/${encodeId(id)}`} state={{ id: id }}>
        <p>
          <strong>{TruncateName(album.english_name)}</strong>
        </p>
        <img
          className="album-display-photo"
          src={album.picture_url}
          alt={'album photo of ' + album.english_name}
          onError={(event) => {
            event.target.src = '/dubu_face_260.jpg';
            event.onerror = null;
          }}
        />
      </Link>
    </div>
  );
};

export default AllAlbumsDetails;
