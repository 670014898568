import { BrowserRouter, Routes, Route } from 'react-router-dom';

// pages & components
import AlbumPage from './pages/AlbumPage';
import ArtistPage from './pages/Artist/ArtistPage';
import SongPage from './pages/SongPage';

import ArtistCreditsPage from './pages/Artist/ArtistCreditsPage';
import ArtistSongsPage from './pages/Artist/ArtistSongsPage';
import ArtistAlbumsPage from './pages/Artist/ArtistAlbumsPage';
import AboutPage from './pages/AboutPage';
import ArtistDiscoveryPage from './pages/ArtistDiscoveryPage';
import ArtistDiscoveryListPage from './pages/ArtistDiscoveryListPage';
import FeedbackPage from './pages/FeedbackPage';
import Home from './pages/Home';
import Navbar from './components/Navbar/Navbar';
import PageNotFoundPage from './pages/PageNotFoundPage';
import TopCreditsPage from './pages/TopCreditsPage';
import Footer from './Footer/Footer';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import ArtistCreditsIconPage from './pages/Artist/ArtistCreditsIconPage';
import SearchResultsPage from './pages/SearchResults';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <div className="pages">
          <Routes>
            <Route path="/artist/:artist/credits-featured" element={<ArtistCreditsIconPage />} />
            <Route path="/artist/:artist/credits-written" element={<ArtistCreditsIconPage />} />
            <Route path="/artist/:artist/credits-composed" element={<ArtistCreditsIconPage />} />
            <Route path="/artist/:artist/credits-arranged" element={<ArtistCreditsIconPage />} />
            <Route path="/artist/:artist/credits" element={<ArtistCreditsPage />} />
            <Route path="/artist/:artist/songs" element={<ArtistSongsPage />} />
            <Route path="/artist/:artist/albums" element={<ArtistAlbumsPage />} />

            <Route path="/artist/:artist" element={<ArtistPage />} />
            <Route path="/song/:song" element={<SongPage />} />
            <Route path="/album/:album" element={<AlbumPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/top-credits" element={<TopCreditsPage />} />
            <Route path="/artist-discovery/:type" element={<ArtistDiscoveryPage />} />
            <Route path="/artist-discovery-list" element={<ArtistDiscoveryListPage />} />
            <Route path="/search" element={<SearchResultsPage />} />
            <Route path="/feedback" element={<FeedbackPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/" element={<Home />} />
            <Route path="*" element={<PageNotFoundPage />} />
          </Routes>
          <Footer />
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
