import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// components
import SongDetails from '../components/SongDetails';
import PageNotFoundPage from './PageNotFoundPage';
import { decodeId } from '../components/HelperMethods';

const SongPage = () => {
  const [songs, setSong] = useState(null);
  const [credits, setCredits] = useState(null);
  const [artistinfos, setArtistInfo] = useState(null);
  const [albuminfos, setAlbumInfo] = useState(null);
  const [error, setError] = useState(null);
  let { state } = useLocation();
  let song_id;

  // console.log(state);

  if (!state) {
    song_id = decodeId(Number(window.location.href.split('/').slice(-1)[0]));
  } else {
    song_id = state.song_id;
  }

  // console.log(song_id);
  useEffect(() => {
    const fetchSong = async () => {
      const response = await fetch('/api/songs/song/' + song_id);
      const json = await response.json();

      if (response.ok) {
        setSong(json);
      } else if (response.status == 404 && !error) {
        setError('Song does not exist');
      } else {
        setError(response.status + ' - ' + json.error);
      }
    };

    const fetchCredits = async () => {
      const response = await fetch('/api/credits/song/' + song_id);
      const json = await response.json();

      if (response.ok) {
        setCredits(json);
      } else {
        setError(response.status + ' - ' + json.error);
      }
    };

    const fetchArtistInfo = async () => {
      const response = await fetch('/api/songs/artistinfo/' + song_id);
      const json = await response.json();

      if (response.ok) {
        setArtistInfo(json);
      } else if (response.status == 404 && !error) {
        setError('Artist info does not exist');
      } else {
        setError(response.status + ' - ' + json.error);
      }
    };

    const fetchAlbumInfo = async () => {
      const response = await fetch('/api/songs/albuminfo/' + song_id);
      const json = await response.json();

      if (response.ok) {
        setAlbumInfo(json);
      } else if (response.status == 404 && !error) {
        setError('Album info does not exist');
      } else {
        setError(response.status + ' - ' + json.error);
      }
    };

    fetchSong();
    fetchCredits();
    fetchArtistInfo();
    fetchAlbumInfo();
  }, []);

  if (error) return <PageNotFoundPage msg={error} />;

  return (
    <div className="padding">
      <div className="song-page">
        <Helmet>
          {songs && artistinfos && (
            <title>
              {songs[0].english_name} by{' '}
              {artistinfos
                .map((a) => `${Object.values(a.artist_english_name).join('')}`)
                .join(', ')}{' '}
              - dubu
            </title>
          )}
          {!songs && <title>loading song - dubu</title>}
          {songs && (
            <meta
              name="keywords"
              content={'kpop, dubu, database, kpop credits, credits, ' + songs[0].english_name}
            />
          )}
          {!songs && (
            <meta name="keywords" content={'kpop, dubu, database, kpop credits, credits'} />
          )}
          {songs && artistinfos && (
            <meta
              name="description"
              content={
                'Song page: ' +
                songs[0].english_name +
                ' by ' +
                artistinfos
                  .map((a) => `${Object.values(a.artist_english_name).join('')}`)
                  .join(', ')
              }
            />
          )}
        </Helmet>
        {credits && songs && artistinfos && albuminfos && (
          <SongDetails
            song={songs[0]}
            artistinfo={artistinfos}
            albuminfo={albuminfos[0]}
            credits={credits}
          />
        )}
        <div className="all-info">
          {songs && songs[0].logged_at && (
            <i>data last updated on {songs[0].logged_at.toString().slice(0, 10)}.</i>
          )}
        </div>
      </div>
    </div>
  );
};

export default SongPage;
