import { Link } from 'react-router-dom';
import { encodeId } from './HelperMethods';

const SongList = ({ song }) => {
  const song_id = song.song_id;
  const album_id = song.melon_album_id;
  let names = '';
  song.artist_names.forEach((name) => (names += name + ', '));
  names = names.substring(0, names.length - 2);
  // var name = song.english_name
  //   .toLowerCase()
  //   .replaceAll(' ', '-')
  //   .replaceAll(/"([^"]+(?="))"/g, '')
  //   .replaceAll("'", '')
  //   .replaceAll('/', '-'); // TODO simplify this

  return (
    <div className="song-list">
      {song.track_order}.{' '}
      <Link to={`/song/${encodeId(song_id)}`} state={{ song_id: song_id, album_id: album_id }}>
        <p>
          {song.is_title && '✰ '}
          {song.is_explicit && '🅴 '}
          {song.english_name}
        </p>
      </Link>
      <span className="by-song-artist">{names}</span>
    </div>
  );
};

export default SongList;
