import { useEffect, useState } from 'react';
import MembershipDetails from './MembershipDetails';
import PhotoMethods from './HelperMethods';
import { ReactComponent as ShareIcon } from '../images/arrow-up-from-bracket-solid.svg';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const ArtistDetails = ({ artist, memberships }) => {
  const [members, setMembers] = useState(null);
  const tooltip = <Tooltip id="tooltip">copy artist link</Tooltip>;
  const copied_tooltip = <Tooltip id="tooltip">copied artist url!</Tooltip>;
  const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);
  const SUBUNIT = 'subunit';
  const PRODUCTION = 'production';
  const copyLink = () => {
    // Your copy link logic here
    navigator.clipboard.writeText(window.location.href);
    setShowCopiedTooltip(true);
    setTimeout(() => {
      setShowCopiedTooltip(false);
    }, 1000); // Tooltip will disappear after 1 second
  };

  useEffect(() => {
    const fetchMembers = async () => {
      const response = await fetch('/api/memberships/group/' + artist.melon_artist_id);
      const json = await response.json();
      // process json for multiple rows before setting members
      if (response.ok) {
        setMembers(json);
      }
    };

    fetchMembers();
  }, []);

  return (
    <div className="artist-details">
      <div className="artist-photo-wrapper">
        <img
          className="main-photo"
          src={PhotoMethods(artist.picture_url)}
          alt={'artist photo of ' + artist.english_name + ', ' + artist.korean_name}
          onError={(event) => {
            event.target.src = '/dubu_face.jpg';
            event.onerror = null;
          }}
        />
      </div>
      <div className="right-card">
        <h7>
          {artist.group_type == PRODUCTION && 'PRODUCTION '}
          {artist.group_type == SUBUNIT && 'SUBUNIT '}
          {artist.is_group && 'GROUP'} {!artist.is_group && 'SOLO'}
          {artist.debut_date && ' • ' + artist.debut_date + ' DEBUT'}
        </h7>

        {artist.melon_artist_id != -1 && (
          <h1 className="main-text">
            {artist.english_name}{' '}
            <OverlayTrigger placement="bottom" overlay={tooltip} trigger="hover">
              <button className="share-icon" onClick={copyLink} style={{ paddingLeft: '1em' }}>
                <OverlayTrigger
                  rootClose
                  placement="bottom"
                  overlay={copied_tooltip}
                  show={showCopiedTooltip}>
                  <ShareIcon style={{ display: 'flex', width: '.4em' }} />
                </OverlayTrigger>
              </button>
            </OverlayTrigger>
          </h1>
        )}
        <h1 className="main-text">{artist.melon_artist_id == -1 && artist.english_name}</h1>
        <h4>{artist.korean_name}</h4>
        <br></br>
        <br></br>
        {members && members.length > 0 && (
          <MembershipDetails
            memberships={members.filter((m) => m.active == true && !m.group_type)}
            type="members"></MembershipDetails>
        )}
        {members && members.length > 0 && (
          <MembershipDetails
            memberships={members.filter((m) => m.active == false && !m.group_type)}
            type="past members"></MembershipDetails>
        )}
        {/* <br></br> */}

        {memberships && memberships.length > 0 && (
          <MembershipDetails
            memberships={memberships.filter((m) => m.active == true && m.group_type != PRODUCTION)}
            type="groups"></MembershipDetails>
        )}
        {memberships && memberships.length > 0 && (
          <MembershipDetails
            memberships={memberships.filter(
              (m) => m.active == false && (m.group_type == SUBUNIT || !m.group_type)
            )}
            type="past groups"></MembershipDetails>
        )}
        {memberships && memberships.length > 0 && (
          <MembershipDetails
            memberships={memberships.filter((m) => m.group_type == PRODUCTION)}
            type="production groups"></MembershipDetails>
        )}
        {members && members.length > 0 && (
          <MembershipDetails
            memberships={members.filter((m) => m.group_type == SUBUNIT)}
            type="subunits"></MembershipDetails>
        )}
      </div>
    </div>
  );
};

export default ArtistDetails;
